import React from 'react'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

const SELECTS = require('../Datas/Selects')

const SportsPassFormFilterTypes = ({sportsPassFilterFilterActiveTypes, OnChangeFilterTypes}) => 
	<Select 
		className="generic-filters-select generic-filters-select-type sports-pass-filters-select sports-pass-filters-select-type" 
		name="sports-pass-filters-type" 
		placeholder="Type de sport" 
		options={SELECTS['types']} 
		onChange={OnChangeFilterTypes}
		value={sportsPassFilterFilterActiveTypes}
		simpleValue
		clearable={false}
		searchable={false}
	/>

export default SportsPassFormFilterTypes
