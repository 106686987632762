exports.types = [
    { value: '', label: 'Type de sport', className: 'option-type' },
    { value: 'Individuels', label: 'Individuels', className: 'option-type' },
    { value: 'Santé/Bien être', label: 'Santé/Bien être', className: 'option-type' },
    { value: 'Collectifs', label: 'Collectifs', className: 'option-type' },
    { value: 'En Famille', label: 'En Famille', className: 'option-type' },
];
exports.activities = [
    { value: '', label: 'Toutes les activités', className: 'option-type' },
    { value: 'ACCUEIL HANDICAP SPORT PASS sur RDV', label: 'ACCUEIL HANDICAP SPORT PASS sur RDV', className: 'option-activity' },
    { value: 'AQUA GYM', label: 'AQUA GYM', className: 'option-activity' },
    { value: 'ATHLETISME HANDI-VALID', label: 'ATHLETISME HANDI-VALID', className: 'option-activity' },
    { value: 'BABY BALLES', label: 'BABY BALLES', className: 'option-activity' },
    { value: 'BABY BOXE', label: 'BABY BOXE', className: 'option-activity' },
    { value: 'BADMINTON', label: 'BADMINTON', className: 'option-activity' },
    { value: 'BASKET', label: 'BASKET', className: 'option-activity' },
    { value: 'BMX', label: 'BMX', className: 'option-activity' },
    { value: 'BODY SCULPT', label: 'BODY SCULPT', className: 'option-activity' },
    { value: 'BOXE ANGLAISE', label: 'BOXE ANGLAISE', className: 'option-activity' },
    { value: 'BOXE EDUCATIVE', label: 'BOXE EDUCATIVE', className: 'option-activity' },
    { value: 'CIRCUIT FORME', label: 'CIRCUIT FORME', className: 'option-activity' },
    { value: 'CIRCUIT TRAINING', label: 'CIRCUIT TRAINING', className: 'option-activity' },
    { value: 'CIRCUIT TRAINING + MUSCULATION', label: 'CIRCUIT TRAINING + MUSCULATION', className: 'option-activity' },
    { value: 'COGNIFIT', label: 'COGNIFIT', className: 'option-activity' },
    { value: 'CROSS TRAINING', label: 'CROSS TRAINING', className: 'option-activity' },
    { value: 'DANSE CLASSIQUE', label: 'DANSE CLASSIQUE', className: 'option-activity' },
    { value: 'ESCALADE', label: 'ESCALADE', className: 'option-activity' },
    { value: 'ESCALADE FAMILLE', label: 'ESCALADE FAMILLE', className: 'option-activity' },
    { value: 'FITNESS', label: 'FITNESS', className: 'option-activity' },
    { value: 'FUTSAL', label: 'FUTSAL', className: 'option-activity' },
    { value: 'GYM MOTRICITÉ', label: 'GYM MOTRICITÉ', className: 'option-activity' },
    { value: 'GYMNASTIQUE', label: 'GYMNASTIQUE', className: 'option-activity' },
    { value: 'MARCHE NORDIQUE', label: 'MARCHE NORDIQUE', className: 'option-activity' },
    { value: 'MULTISPORTS', label: 'MULTISPORTS', className: 'option-activity' },
    { value: 'MULTISPORTS SANTÉ', label: 'MULTISPORTS SANTÉ', className: 'option-activity' },
    { value: 'MUSCULATION', label: 'MUSCULATION', className: 'option-activity' },
    { value: 'OUISITI - PARCOURS MOTRICITÉ', label: 'OUISITI - PARCOURS MOTRICITÉ', className: 'option-activity' },
    { value: 'PILATES', label: 'PILATES', className: 'option-activity' },
    { value: 'RUNNING', label: 'RUNNING', className: 'option-activity' },
    { value: 'SOPHROLOGIE', label: 'SOPHROLOGIE', className: 'option-activity' },
    { value: 'SPORT EN FAMILLE', label: 'SPORT EN FAMILLE', className: 'option-activity' },
    { value: 'STRETCHING', label: 'STRETCHING', className: 'option-activity' },
    { value: 'TENNIS', label: 'TENNIS', className: 'option-activity' },
    { value: 'TENNIS DE TABLE', label: 'TENNIS DE TABLE', className: 'option-activity' },
    { value: 'VOLLEY-BALL', label: 'VOLLEY-BALL', className: 'option-activity' },
    { value: 'VÉLO APPRENTISSAGE / MULTISPORTS', label: 'VÉLO APPRENTISSAGE / MULTISPORTS', className: 'option-activity' },
    { value: 'VÉLO APPRENTISSAGE / RANDONNÉE', label: 'VÉLO APPRENTISSAGE / RANDONNÉE', className: 'option-activity' },
    { value: 'YOGA', label: 'YOGA', className: 'option-activity' }
];
exports.ages = [
    { value: '', label: 'Tous les âges', className: 'option-age' },
    { value: '16 ans et +', label: '16 ans et +', className: 'option-age' },
    { value: '6 - 15 ans', label: '6 - 15 ans', className: 'option-age' },
    { value: '3 - 5 ans', label: '3 - 5 ans', className: 'option-age' },
];
exports.levels = [
    { value: '', label: 'Niveau', className: 'option-level' },
    { value: 'Débutant', label: 'Débutant', className: 'option-level' },
    { value: 'Intermédiaire', label: 'Intermédiaire', className: 'option-level' },
    { value: 'Confirmé', label: 'Confirmé', className: 'option-level' },
];
exports.days = [
    { value: '', label: 'Tous les jours', className: 'option-day' },
    { value: 'Lundi', label: 'Lundi', className: 'option-day' },
    { value: 'Mardi', label: 'Mardi', className: 'option-day' },
    { value: 'Mercredi', label: 'Mercredi', className: 'option-day' },
    { value: 'Jeudi', label: 'Jeudi', className: 'option-day' },
    { value: 'Vendredi', label: 'Vendredi', className: 'option-day' },
    { value: 'Samedi', label: 'Samedi', className: 'option-day' },
    { value: 'Dimanche', label: 'Dimanche', className: 'option-day' },
];