import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as loadingActions from '../../../state/actions/loading'

import SportsPassSingleItem from "./SportsPassSingleItem"

class SportsPassSingle extends Component {
	_isMounted = false
	
	constructor(props, context) {
		super(props, context)

		const {urlSingle} = this.props

		// Liste des filtres actifs
		this.state = {
			urlSingle: urlSingle ? urlSingle.trim() : '',
			post: []
		}
	}

	componentDidMount(){
		this._isMounted = true
		const {loadingOn} = this.props

		this.launchSingle()
		loadingOn('BonsPlansJeunesSingle')
	}

	componentWillUnmount() {
		const {loadingOff} = this.props

		loadingOff('BonsPlansJeunesSingle')
		this._isMounted = false
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		prevState.urlSingle !== this.state.urlSingle && this.launchSingle()
	}

	launchSingle() {
		const {loadingOff} = this.props
		const {urlSingle} = this.state
        let id = urlSingle.split('-')
        id = id[id.length-1]

		if( parseInt( id ) > 0 ) {
			const singleQuery = 'https://carto.ville-roubaix.fr/server/rest/services/OpenData/SPORTS/FeatureServer/4/query?where=&objectIds=' + id + '&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields=+activite%2C+adherent_inscrit%2C+adherent_maximum%2C+adresse%2C+age%2C+coach_1%2C+coach_2%2C+creneau%2C+description%2C+equipement%2C+heures%2C+horaire%2C+image_%2C+infos%2C+jour%2C+last_edited_date%2C+latitude%2C+longitude%2C+niveau%2C+num%2C+objectid%2C+place_restante%2C+sport_pass%2C+tranche_age%2C+type_sport&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation=&f=pjson';
			fetch(singleQuery,
            {
                    headers: {
                    },
                    method: "GET"
                }
            ).then(response => {
				if (response.status && response.status > 200 ) {
					console.warn(response)
				} else {
					return response.json()
				}
			}).then(({features}) => {
				this._isMounted && this.setState({ 
					post:features[0].attributes
				})
				loadingOff('BonsPlansJeunesSingle')
			}).catch(function(error) {
				console.warn("error")
				console.warn(error)
			})
		}
	}

	render(){
		const {location} = this.props
		const {post} = this.state

		return (
			<div>
				<div className="offline-fallback-fix-no-idea-why"></div>
				<div className="sports-pass-single">
					<SportsPassSingleItem 
						shareUrl={location.href}
						postItem={post}
					/>
				</div>
			</div>
		)
	}
}

export default connect(null, loadingActions)(SportsPassSingle)
