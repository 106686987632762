import React from 'react';
import {Link} from 'gatsby'

const SportsPassBreadcrumb = ({SingleTitle}) => (
	<div className="d-none d-lg-block d-md-block d-sm-block order-bottom py-2">
		<div className="container">
			<nav className="" aria-label="breadcrumb">
				<ol className="breadcrumb">
					<li className="breadcrumb-item"><Link className="text-dark" to="/">Accueil</Link></li>
					<li className="breadcrumb-item"><Link className="text-dark" to="/services-infos-pratiques/">Services & infos pratiques</Link></li> 
					<li className="breadcrumb-item"><Link className="text-dark" to="/services-infos-pratiques/sports/">Sports</Link></li> 
					{ SingleTitle ?  <li className="text-secondary breadcrumb-item"><Link className="text-dark" to="/services-infos-pratiques/sports/annuaire-sport-pass/">Annuaire Sport Pass</Link></li> : <li className="text-secondary breadcrumb-item">Annuaire Sport Pass</li> }
					{ SingleTitle ? <li className="text-secondary breadcrumb-item">{SingleTitle}</li> : '' }
				</ol>
			</nav>
		</div>
	</div>
)

export default SportsPassBreadcrumb;