import React from 'react';
import { connect } from 'react-redux'
import SportsPassFormFilterTypes from './SportsPassFormFilterTypes';
import SportsPassFormFilterActivity from './SportsPassFormFilterActivity';
import SportsPassFormFilterAges from './SportsPassFormFilterAges';
import SportsPassFormFilterLevel from './SportsPassFormFilterLevel';
import SportsPassFormFilterDays from './SportsPassFormFilterDays';
import SportPassFormFilterNoFull from './SportPassFormFilterNoFull';

import { getCookiebox } from '../../../state/reducers/cookiebox'
import { iframeParse } from '../../../utils/functions'

const SportsPassForm = ({
	SportsPassFilterMySearch,
	SportsPassFilterFilterActiveTypes, OnChangeFilterTypes,
	SportsPassFilterFilterActiveActivities, OnChangeFilterActivities,
	SportsPassFilterFilterActiveAges, OnChangeFilterAges,
	SportsPassFilterFilterActiveLevels, OnChangeFilterLevels,
	SportsPassFilterFilterActiveDays, OnChangeFilterDays,
	SportsPassFilterFilterActiveNotFull, OnChangeFilterNotFull,
	OnClickShowMap, SportPassShowMap,
	OnSubmit, cookiebox,
	placeholder,
	type,
	onKeyDown,
	onKeyUp
}) => (
	<form id="sports-pass-filters" className="generic-filters sports-pass-filters" onSubmit={OnSubmit}>
		<div className="generic-filters-header sports-pass-filters-header">
			<h1 className="generic-filters-header-title sports-pass-filters-header-title"><span>Bougez</span> avec le Sport Pass</h1>
			<div className="generic-filters-search sports-pass-filters-search">
				<div className="generic-filters-search-group sports-pass-filters-search-group">
					<div className="generic-filters-search-text sports-pass-filters-text" >
						<input id="sports-pass-filters-text" placeholder={placeholder} type={type} onKeyDown={onKeyDown} onKeyUp={onKeyUp} />
					</div>
					<div className="generic-filters-search-submit sports-pass-filters-submit" onClick={OnSubmit} onKeyPress={OnSubmit} role="button" tabIndex={0}>
						<input id="sports-pass-filters-submit" type="submit" value="Go" />
					</div>
				</div>
			</div>
			<div className="sports-pass-filters-header-map">
				<p className="generic-filter-button sports-pass-filter-button"><a rel="noopener noreferrer" className="btn btn-primary btn-lg btn-pink-3" href="https://livemap.getwemap.com/embed.html?emmid=17283&token=K9UCJCEUJ3ZEJ1E3QXTA94YKO" onClick={OnClickShowMap} >Voir la carte</a></p>
				<div className={`sports-pass-filters-header-iframe ${SportPassShowMap ? 'show' : ''}`}>
					<div className="sports-pass-filters-header-iframe-content">
						<span className="close" onClick={OnClickShowMap} onKeyPress={OnClickShowMap} role="button" tabIndex={0}>x</span>
						<div
							className="sports-pass-filters-header-iframe-content-innerhtml"
							dangerouslySetInnerHTML={{ __html: cookiebox ? iframeParse('<iframe title="Carte annuaire sport pass" width="100%" height="500" src="https://livemap.getwemap.com/embed.html?emmid=17283&token=K9UCJCEUJ3ZEJ1E3QXTA94YKO" frameborder="0" allowfullscreen allow="geolocation; camera; accelerometer; gyroscope; magnetometer; fullscreen"></iframe>') : '<iframe title="Carte annuaire sport pass" width="100%" height="500" src="https://livemap.getwemap.com/embed.html?emmid=17283&token=K9UCJCEUJ3ZEJ1E3QXTA94YKO" frameborder="0" allowfullscreen allow="geolocation; camera; accelerometer; gyroscope; magnetometer; fullscreen"></iframe>' }}
						></div>
					</div>
				</div>
			</div>
		</div>
		<div className="generic-fields sports-pass-filters-fields">
			<SportsPassFormFilterTypes
				sportsPassFilterFilterActiveTypes={SportsPassFilterFilterActiveTypes}
				OnChangeFilterTypes={OnChangeFilterTypes}
			/>
			<SportsPassFormFilterActivity
				sportsPassFilterFilterActiveActivities={SportsPassFilterFilterActiveActivities}
				OnChangeFilterActivities={OnChangeFilterActivities}
			/>
			<SportsPassFormFilterAges
				sportsPassFilterFilterActiveAges={SportsPassFilterFilterActiveAges}
				OnChangeFilterAges={OnChangeFilterAges}
			/>
			<SportsPassFormFilterLevel
				sportsPassFilterFilterActiveLevels={SportsPassFilterFilterActiveLevels}
				OnChangeFilterLevels={OnChangeFilterLevels}
			/>
			<SportsPassFormFilterDays
				sportsPassFilterFilterActiveDays={SportsPassFilterFilterActiveDays}
				OnChangeFilterDays={OnChangeFilterDays}
			/>
			<SportPassFormFilterNoFull
				sportsPassFilterFilterActiveNotFull={SportsPassFilterFilterActiveNotFull}
				OnChangeFilterNotFull={OnChangeFilterNotFull}
			/>
		</div>
	</form>
)

const mapStateToProps = state => ({
	cookiebox: getCookiebox(state)
})

export default connect(mapStateToProps)(SportsPassForm);
