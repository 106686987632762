import React from 'react'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

const SELECTS = require('../Datas/Selects')

const SportsPassFormFilterLevel = ({sportsPassFilterFilterActiveLevels, OnChangeFilterLevels}) => 
	<Select 
		className="generic-filters-select generic-filters-select-level sports-pass-filters-select sports-pass-filters-select-level" 
		name="sports-pass-filters-level" 
		placeholder="Niveau" 
		options={SELECTS['levels']} 
		onChange={OnChangeFilterLevels}
		value={sportsPassFilterFilterActiveLevels}
		simpleValue
		clearable={false}
		searchable={false}
	/>

export default SportsPassFormFilterLevel
