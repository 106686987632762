import React from 'react'
import { Router } from "@reach/router"

import SportsPassList from '../../../components/SportsPass/List/SportsPassList'
import SportsPassSingle from '../../../components/SportsPass/Single/SportsPassSingle'

const SportsPassResults = ({data}) =>
	<div>
		{typeof window !== 'undefined'  &&
			<Router>
				<SportsPassList path="/services-infos-pratiques/sports/annuaire-sport-pass/:myFilterType/:myFilterValue" />
				<SportsPassList path="/services-infos-pratiques/sports/annuaire-sport-pass/" />
				<SportsPassSingle path="/services-infos-pratiques/sports/annuaire-sport-pass/:urlSingle" />
			</Router>
		}
	</div>

export default SportsPassResults
