import React from 'react'

const Pagination = ({offset, limit, totals, currentPage, OnClickPaginate}) => {
  const pageMax = totals > 0 ? Math.ceil(totals/limit) : 1
  const cur = currentPage === 0 ? 1 : currentPage
  let page = 1
  let pageList = []

  if(totals > 0){
    pageList.push(page)
    while(offset + limit*page < totals && page < pageMax){
      page++
      pageList.push(page)
    }
  }

  return (
    <div className="pagination">
      {pageMax > 1 &&
        <ul className="pagination-list">
          {cur > 1 &&
            <li className="pagination-item pagination-prev-first">
              <a href="./" data-num-page="1" onClick={OnClickPaginate}> &lt;&lt; </a>
            </li>
          }
          {cur > 1 &&
            <li className="pagination-item pagination-prev">
              <a href="./" data-num-page={cur-1 > 0 ? cur-1 : 1} onClick={OnClickPaginate}> &lt; </a>
            </li>
          }
          {pageList.map((p, index) => 
              <li className={`pagination-item${(p === 1 && currentPage === 0) || p.toString() === currentPage.toString() ? ' current' : ''}`} key={index}>
                <a href="./" data-num-page={p} onClick={OnClickPaginate} >{p}</a>
              </li>
          )}
          {cur < pageMax &&
            <li className="pagination-item pagination-next">
              <a href="./" data-num-page={cur > 0 ? cur+1 : cur+2} onClick={OnClickPaginate}> &gt; </a>
            </li>
          }
          {cur < pageMax &&
            <li className="pagination-item pagination-next-last">
              <a href="./" data-num-page={pageMax} onClick={OnClickPaginate}> &gt;&gt; </a>
            </li>
          }
        </ul>
      }
    </div>
  )
}

export default Pagination
