import React from 'react'
import Select from 'react-select'

import 'react-select/dist/react-select.css'

const SELECTS = require('../Datas/Selects')

const SportsPassFormFilterActivity = ({sportsPassFilterFilterActiveActivities, OnChangeFilterActivities}) => 
	<Select 
		className="generic-filters-select generic-filters-select-activity sports-pass-filters-select sports-pass-filters-select-activity" 
		name="sports-pass-filters-activity" 
		placeholder="Toutes les activités" 
		options={SELECTS['activities']} 
		onChange={OnChangeFilterActivities}
		value={sportsPassFilterFilterActiveActivities}
		simpleValue
		clearable={false}
		searchable={false}
	/>

export default SportsPassFormFilterActivity
